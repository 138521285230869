/* @media (min-width: 1200px) and (max-width: 1302px) {
  .start-data-end {
    width: 250px !important;
  }

} */

@media (min-width: 1200px) and (max-width: 1300px) {
  .borderInput label {
    font-size: 9px;
  }

  .quotations-text {
    font-size: 10px;
  }

  .total-cost {
    font-size: 10px;
  }

  .quoteAmount-input input {
    width: 150px;
  }
}

@media (min-width: 1301px) and (max-width: 1400px) {
  .quotations-text {
    font-size: 11px;
  }

}

@media (min-width: 1301px) and (max-width: 1350px) {
  .total-cost {
    font-size: 10px;
  }

  .quoteAmount-input input {
    width: 170px;
  }
}