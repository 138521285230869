.haaderDrawerComponent .mantine-Drawer-inner .mantine-Drawer-content {
    background: #1E55A5 0% 0% no-repeat padding-box;
    flex: 0 0 13rem;
    color: #FFFFFF;
}

.haaderDrawerComponent .mantine-Drawer-inner .mantine-Drawer-content .mantine-Drawer-body .mantine-Accordion-item button {
    color: #FFFFFF;
    padding-left: 0px;
    padding-right: 0px;
}

.haaderDrawerComponent .mantine-Drawer-inner .mantine-Drawer-content .mantine-Drawer-body .mantine-Accordion-item {
    background: transparent !important;
}

.activeClassIcon {
    background: #e0edff;
    /* background: #D6E5F9; */
    padding: 4px;
    border-radius: 4px;
}