body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cP {
  cursor: pointer;
}

.disabled-cursor {
  cursor: no-drop;
}

.mantine-DatePickerInput-root {
  width: 100%;
}

.deliveryBtn:hover {
  background-color: #d6e5f9;
}

.mantine-Pagination-control[data-active] {
  background-color: #1e55a5;
}

.mantine-Pagination-control[data-active]:hover {
  background-color: #1e55a5 !important;
}

.mantine-Tabs-tabsList .mantine-Tabs-tab[data-active] {
  background: rgb(214, 229, 249);
  color: rgb(30, 85, 165);
  font-weight: 600;
  font-size: 0.7rem;
}

/* table common design */

.listDataTable {
  max-height: 65vh;
  overflow-y: auto !important;
}

.listDataTable .rdt_TableHeadRow {
  border: unset;
  background: #f1f1f1;
  color: #302f46;
  font-weight: 600;
  min-height: 35px;
}

.listDataTable .rdt_TableRow {
  border: unset !important;
  min-height: 35px;
  color: #302f46;
}

.listDataTable .rdt_TableRow .rdt_TableCell {
  border-right: 2px solid #f1f1f1;
}

.listDataTable .rdt_TableRow {
  border-left: 2px solid #f1f1f1 !important;
}

.__rdt_custom_sort_icon__ {
  position: relative;
  top: 2px;
  left: 2px;
}

.tableAddBtn {
  color: #393939;
  border: 1px solid #393939;
  border-radius: 10px;
}

.filterCheckBox input {
  cursor: pointer;
}

.filterCheckBox label {
  cursor: pointer;
}

.selectaddList:hover {
  background: #fafbfc;
}

.uploader__placeholder {
  min-height: 70px !important;
  min-width: 70px !important;
  overflow: visible !important;
  background-image: url("./assets/icons/Icon\ feather-image.png") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

/* .imageUploadPlaceholder .uploader__placeholder {
  background-image: url('./assets/icons/Icon\ feather-image.png') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
} */

.uploader__file_input_label {
  top: -8% !important;
  left: 80% !important;
  transform: none !important;
}

.default-image-parent {
  position: relative;
}

.default-image {
  position: absolute;
  z-index: 1;
}

.default-image img {
  object-fit: fill !important;
  width: 70px !important;
  height: 70px !important;
  /* width: 89px !important;
  height: 89px !important; */
}

.mantine-ufuzch {
  border: none !important;
}

.refInput {
  border: none;
  height: 0px;
  padding: 0px;
}

.refInput:focus-visible {
  outline: unset;
}

/* input:focus-within {
  border-color: transparent !important;
} */

.mantine-Switch-body {
  align-items: center;
}

.beforeIcon .mantine-Input-icon {
  top: 0%;
  left: 0%;
  position: absolute;
  height: 100%;
  background-color: #dcdcdc;
  border-radius: 5px 0px 0px 5px;
  font-size: 14px;
  color: #5a5a5a;
}

/* wood */

.woodBoxCtr {
  background: #fcfcfc 0% 0% no-repeat padding-box;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  height: fit-content;
  margin: 10px;
}

.editButton:hover {
  background: #e8f1fd;
}

.editButton {
  background: #e8f1fd !important;
  color: #1e55a5 !important;
  min-width: 5rem;
}

.tableDropdown input {
  background: transparent;
  padding: 0px;
}

.text-align {
  text-align: center !important;
}

.materialHead {
  background: #bdddff 0% 0% no-repeat padding-box;
}

.fastnersHead {
  background: #ffe3f1 0% 0% no-repeat padding-box;
}

.deckboardHead {
  background: #d2ffce 0% 0% no-repeat padding-box;
}

.fullwidthModalForm .mantine-Modal-inner {
  padding-left: 0px;
  padding-right: 0px;
}

.viewMatTable > thead > tr > th {
  border-bottom: none !important;
}

.viewMatTable > tbody > tr > td {
  border-right: 1px solid #dee2e6 !important;
  border-top: none !important;
}

.viewMatTable {
  border-bottom: 1px solid #dee2e6 !important;
}

.orderTableCtr {
  background: #fafafa 0% 0% no-repeat padding-box;
  border: 1px solid #e9e9e9;
  border-radius: 7px;
}

.orderTableCtr > table > thead > tr > th {
  border-bottom: none;
  color: #5a5a5a;
  font-weight: 600;
  padding-bottom: 0px;
}

.orderTableCtr > table > tbody > tr > td {
  border-top: none;
}

/* dailySheet table */

/* .dailtySheetExpandTable .rdt_TableCell div:first-child {
  width: 100%;
} */

.dailtySheetExpandTable .mantine-Accordion-item {
  border: none;
}

.dailtySheetExpandTable .mantine-Accordion-control {
  padding-right: 0px;
  padding-left: 15px;
}

.dailtySheetExpandTable .mantine-Accordion-chevron {
  margin-left: -0.75rem;
}

.dailtySheetExpandTable .rdt_TableCell {
  padding: 0px;
}

.workOrderPOTable .dahnUA div:first-child {
  width: auto !important;
}

.dahnUA div:first-child {
  width: 100%;
}

.mantine-Accordion-item {
  width: 100% !important;
}

.mantine-Accordion-item .bg-uploading {
  background: #fcfcfc 0% 0% no-repeat padding-box;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
}

.password-type2 {
  width: 164px;
}

.palletViewOrder .mantine-Modal-header {
  display: none;
}

.textCaptilize {
  text-transform: capitalize;
}

.nodatatext {
  background-color: red;
  font-size: 12px;
  text-align: center;
}

.form-table-pdf {
  display: flex;
  align-items: center;
  gap: 5px;
}

.mantine-TextInput-input[data-with-icon] {
  padding-left: 2.2rem;
}

.tableRadio .mantine-Radio-inner {
  width: auto !important;
}

.fixTableHead {
  overflow-y: auto;
  max-height: 300px;
}

.fixTableHead thead th {
  position: sticky;
  top: 0;
  background: #f1f1f1;
  z-index: 99;
}

.activeColorText input {
  color: #1e55a5 !important;
  font-weight: bold;
  opacity: 1 !important;
}

.customPagination .mantine-Pagination-control[data-active] {
  border: none;
  background: #1e55a5;
  color: white;
}

.customPagination .mantine-Pagination-control {
  border: none;
  height: 22px;
  min-width: 22px;
}

.mantine-Input-input {
  padding-right: calc(4.875rem / 3) !important;
}

.overFlow-elipisis-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.addInput input {
  text-align: center;
}

.workorder_po .mantine-Avatar-root {
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.workorder_po .mantine-Avatar-placeholder {
  font-size: 12px;
}

.completedCircle {
  background: #09bd33;
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  margin-left: 5px;
}

.pendingCircle {
  background: #bd9f09;
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  margin-left: 5px;
}

.lumber-tab .mantine-fq0g4p {
  border-bottom: none;
}

.mantine-1gy8jg4 {
  min-width: 14vw;
}

.mantine-Accordion-item {
  width: 100%;
}

.quoteBottomTable thead th {
  font-size: 12px;
  color: #5a5a5a;
  text-align: left;
}

.quoteBottomTable tbody tr td {
  border-top: none;
}

.quoteBottomTable tbody tr td:first-child {
  padding-left: 0px;
}

.quoteTb {
  background: #fcfcfc 0% 0% no-repeat padding-box;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 10px 10px 5px 10px;
}

.quoteAmount-input input {
  background: #f2f2f2;
  border: none !important;
  border-radius: 5px;
}

.quoteAmount-input .mantine-Input-icon {
  background: #dcdcdc;
  color: #5a5a5a;
  font-size: 12px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.quoteAmount-input .mantine-Input-input[data-with-icon] {
  padding-left: 2.5rem;
}

.elipis-text {
  min-width: 120px;
  word-wrap: break-word;
  display: inline-block;
}

.elipis-text-small {
  min-width: 80px;
  word-wrap: break-word;
  display: inline-block;
  word-break: break-all;
}

.selectDelIcon {
  font-size: 10px;
}

/* New change */
.taxiesSelect .mantine-1b2nit1 {
  padding-left: calc(0.875rem / 3) !important;
  padding-right: 0.875rem !important;
}

/* New change */
.taxiesSelect .mantine-Input-wrapper {
  width: 80%;
}

/* New change */
.addInput .mantine-Input-input {
  padding-right: 0 !important;
}

/* New change */
.quoteAmount-input .mantine-Input-input {
  padding-right: calc(2.875rem / 3) !important;
}

.listDataTable {
  height: 65vh;
}

.jJhCqB div:first-child {
  width: 100%;
}

.cTmUyP div:first-child {
  width: 100%;
}

.mantine-Select-input[data-disabled] {
  opacity: 1;
  color: black;
}

.loaderCenterStyle {
  position: relative;
  margin: 70px 0px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nodata_acc .mantine-Accordion-chevron svg {
  display: none;
}

.addressTable {
  height: auto !important;
}

.field-manual-star {
  color: #fa5252;
}

.addressTableCtr {
  border-collapse: collapse;
  width: 100%;
}

.addressTableCtr td,
.addressTableCtr th {
  border: 1px solid #dddddd;
  font-size: 14px;
  padding: 10px;
  text-align: left;
}

.addressTableCtr th {
  background: #f1f1f1;
  color: #302f46;
}

.addressTable,
.addressTable-1 {
  width: 100%;
}

.addressTable thead,
.addressTable-1 thead {
  background: #f1f1f1;
  color: #302f46;
  font-size: 14px;
  display: block;
  padding: 10px;
}

.addressTable,
.addressTable td {
  border-collapse: collapse;
  padding: 10px;
  font-size: 14px;
}

.addressTable-1,
.addressTable-1 td {
  border: 2px solid #f1f1f1;
  border-collapse: collapse;
  padding: 10px;
  font-size: 14px;
}

.elipis-text-small {
  display: inline-block;
  word-break: break-word;
  cursor: pointer;
}

.website-label {
  font-family: Mont, Regular;
  display: inline-block;
  font-size: 0.65rem;
  font-weight: 500;
  color: #212529;
  word-break: break-word;
  cursor: default;
  margin: 0px;
  padding: 0px;
}

.card {
  width: 170px;
  height: 23px;
  background: #f1f3f5;
  border-radius: 4px;
  margin-top: 2px;
  display: flex;
  padding-left: 12px;
  padding-top: 8px;
  padding-right: 12px;
}

.link-website {
  font-family: Mont, Regular;
  font-size: 0.65rem;
  font-weight: 500;
  color: #212529;
  cursor: pointer;
  text-decoration: none;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
  overflow: hidden;
}

.link-website:hover {
  color: rgb(30, 85, 165);
}

.addInput-quantity .mantine-Input-input {
  padding-right: 0px !important;
}

.word-cont {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.word-content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.website-label-placeholder {
  color: rgb(179, 179, 179);
  font-size: 11px;
}

.startEndDate .mantine-DatePickerInput-input {
  padding-right: 0px !important;
}

.f-none .uploader__btn_wrapper {
  display: none;
}

.uploader__btn svg {
  background: #1e55a5;
  color: white;
  padding: 5px;
  border-radius: 50%;
  position: relative;
  top: 15px;
  left: -15px;
}

.emailfieldsmodal {
  display: flex;
  gap: 10px;
  align-items: center;
}

.emailfieldsmodal-1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mantine-Checkbox-inner {
  width: auto !important;
}

/* .listDataTable .rdt_TableCol:last-child {
  position: sticky;
  right: 0;
  background: #F1F1F1;
}

.listDataTable .rdt_TableCell:last-child {
  position: sticky;
  right: 0;
  background: white;
  border-left: 0.5px solid #F1F1F1;
  z-index: 999;
} */

.mantine-Checkbox-inner input:checked {
  background-color: #1e55a5;
  border-color: #1e55a5;
}

.mantine-Checkbox-inner svg {
  top: 2px;
}

.mantine-Text-root {
  word-wrap: break-word;
}

.centerCheckBox input {
  cursor: pointer;
}

.centerCheckBox .mantine-Checkbox-body {
  justify-content: center;
}

.generate-quotation-hr-1 {
  display: flex;
  width: 100%;
  max-width: 800px;
  background-color: white;
}

.generate-quotation-hr {
  display: flex;
  width: 100%;
  max-width: 800px;
  padding: 20px;
  background-color: white;
}

.generate-quotation-hr-1 hr,
.generate-quotation-hr hr {
  width: 100%;
}

.footer-quote {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 16px;
  text-align: center;
}

.innerCtrHtml p {
  margin: 2px !important;
}

.textLeftCtr input {
  text-align: left;
}

.footer-form {
  display: flex;
  align-items: center;
  gap: 5px;
}

.footer-form span {
  font-weight: 700;
  font-size: 12px;
  min-width: 111px !important;
}

.footer-form input,
.footer-form input:focus {
  border-bottom: 1px solid #1e55a5;
  font-size: 12px;
}

.elpisTable {
  max-width: 295px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.elpisTabless {
  max-width: 270px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.error-padding {
  padding-top: 15px;
}

.rel--error .mantine-InputWrapper-error {
  position: absolute;
  left: 10px;
}

.sc-hLQSwg > div:first-child {
  width: 100%;
}
