 .mantine-input-style-copy {
   height: 1.875rem;
   /* height: 1.375rem; */
   line-height: calc(2.125rem);
   appearance: none;
   resize: none;
   box-sizing: border-box;
   font-size: 0.7rem;
   width: 100%;
   color: rgb(0, 0, 0);
   display: block;
   text-align: left;
   border: 0.0625rem solid transparent;
   background-color: rgb(241, 243, 245);
   min-height: 1.875rem;
   /* min-height: 1.375rem; */
   padding-left: calc(0.75rem);
   padding-right: calc(0.75rem);
   border-radius: 0.25rem;
   font-family: 'Mont';
 }

 .mantine-input-style-copy:focus {
   outline: none;
   border-color: #228be6;
 }

 .mantine-input-style-copy[aria-invalid] {
   color: #fa5252;
   border-color: #fa5252;
 }

 .mantine-input-style-copy:disabled {
   background-color: #f1f3f5;
   color: #000;
   cursor: not-allowed;
   pointer-events: none;
 }

 .mantine-input-style-copy-phone {
   height: 1.875rem;
   /* height: 1.375rem; */
   line-height: calc(2.125rem);
   appearance: none;
   resize: none;
   box-sizing: border-box;
   font-size: 0.7rem;
   width: 100%;
   color: rgb(0, 0, 0);
   display: block;
   text-align: left;
   border: 0.0625rem solid transparent;
   background-color: rgb(241, 243, 245);
   /* min-height: 1.375rem; */
   min-height: 1.875rem;
   padding-left: calc(2.5rem);
   padding-right: calc(0.75rem);
   border-radius: 0.25rem;
   font-family: 'Mont';
 }

 .mantine-input-style-copy-phone:focus {
   outline: none;
   border-color: #228be6;
 }

 .mantine-input-style-copy-phone:disabled {
   background-color: #f1f3f5;
   color: #909296;
   opacity: 0.6;
   cursor: not-allowed;
   pointer-events: none;
 }

 .mantine-input-style-copy-phone[aria-invalid] {
   color: #fa5252;
   border-color: #fa5252;
 }

 .phone-number-relative {
   position: relative;
 }

 .phone-number-absolute {
   top: 0%;
   left: 0%;
   position: absolute;
   height: 100%;
   background-color: #DCDCDC;
   border-radius: 5px 0px 0px 5px;
 }